import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    Chip: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(2),
        },
        fontSize: 15,
        backgroundImage: selected => selected && 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
    },
}))

const MediaChip = (props) => {
    const [selected, setSelected] = useState(false);
    const classes = useStyles(selected);

    useEffect(() => {
        const isSelected = () => {
            props.mediasList.includes(props.label) && setSelected(true);
        }

        isSelected();
    }, []);

    return (
        <Chip 
            label={props.label}
            clickable={true}
            onClick={() => {
                if (props.mediasList.length < 3) {
                    if (!selected) {
                        setSelected(true);
                        props.setMediasList([...props.mediasList, props.label]);
                    } else {
                        setSelected(false);
                        props.setMediasList(props.mediasList.filter((media) => media !== props.label));
                    }
                } else {
                    setSelected(false);
                    props.setMediasList(props.mediasList.filter((media) => media !== props.label));
                }
            }}
            className={classes.Chip}
            disabled={props.mediasList.length >= 3 && !selected ? true : false}
        />
    );
}

export default MediaChip;