import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import medias from '../../../artmedias';
import MediaChip from '../../../Components/MediaChip';

const useStyles = makeStyles((theme) => ({
    MediasInfo: {
        fontWeight: 'bold'
    },
    MediasCaption: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    DoneButton: {
        boxShadow: 'none',
        backgroundColor: 'rgb(62, 149, 239)'
    },
    ChipList: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(70)
        },
        [theme.breakpoints.up('xs')]: {
            wdith: theme.spacing(35)
        }
    },
    BackButton: {
        color: 'gold',
        borderColor: 'gold'
    }
}));

const ChooseGenres = (props) => {
    const classes = useStyles();

    return (
        <Grid container direction='column' alignItems='center' spacing={1}>
            <Grid item>
                <Typography variant='h4' className={classes.MediasInfo}>
                    Choose your art medias
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' className={classes.MediasCaption}>
                    Choose up to 3 art medias to display on your profile
                </Typography>
            </Grid>
            <Grid item container spacing={1} justify='center' className={classes.ChipList}>
                {medias.map((medium) => (
                    <Grid item>
                        <MediaChip 
                            label={medium.media}
                            setMediasList={props.setMediasList}
                            mediasList={props.mediasList}
                            setChooseMedias={props.setChooseMedias}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid item container justify='flex-end' spacing={1} className={classes.ChipList}>
                <Grid item>
                    <Button
                        variant='outlined'
                        size="small"
                        color="primary"
                        onClick={() => {
                            props.setMediasList([]);
                            props.setChooseMedias(false);
                        }}
                        className={classes.BackButton}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        className={classes.DoneButton}
                        size="small"
                        color="primary"
                        onClick={() => props.setChooseMedias(false)}
                    >
                        Done
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChooseGenres;