import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {
    gql,
    useQuery
} from '@apollo/client';

const GET_LOGGEDIN = gql`
  query GetLoggedIn {
    loggedIn @client
  }
`;

const PrivateRoute = ({children, ...rest}) => {

    const {
      data: {loggedIn}
    } = useQuery(GET_LOGGEDIN);
  
    return (
      <Route
        {...rest}
        render={() => 
          loggedIn ? 
          (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
        }
      />
    );
  }

  export default PrivateRoute;