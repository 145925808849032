import React, {useState, useEffect} from 'react';
import {useQuery, gql} from '@apollo/client';
import {userSubVar} from '../../local state/cache';
import {Storage} from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    paddingTop: theme.spacing(2),
  },
  avi: {
    width: theme.spacing(18),
    height: theme.spacing(18)
  },
  labels: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  postCategories: {
    color: 'gray',
  },
  postCatContainer: {
    border: 'solid 1px black',
  },
  postsSection: {
    marginTop: theme.spacing(4),
    borderTop: 'solid 1px lightgray',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  chips: {
    background: 'linear-gradient(45deg, #0028FF 30%, #8959FF 90%)',
    color: 'white'
  }
}))

const GET_USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      name
      displayName
      bio
      joined
      email
      avatar
      labels
      posts {
        id
        genre
        media
        caption
        timestamp
      }
      fanOf {
        id
        name
        displayName
        bio
      }
      fans {
        id
        name
        displayName
        bio
      }
    }
  }
`;

const Profile = () => {
    const [aviURL, setAviURL] = useState('initialState');
    const classes = useStyles();

    const {data} = useQuery(GET_USER, {
        variables: {
            id: userSubVar(),
        },
    });

    useEffect(() => {
        const getAvi = async () => {
          try {
            await Storage.get(data.user.avatar).then((res) => {
              console.log('avi res: ', res);
              setAviURL(res);
            });
          } catch (error) {
            console.log('getAvi ERROR: ', error);
            setAviURL('Image Not Available');
          }
        };
    
        getAvi();
      }, []);

    return (
      <Grid container className={classes.mainDiv} direction='column' alignItems='center'>
        <Grid item>
          <Avatar alt='Avatar' src={aviURL} className={classes.avi} />
        </Grid>
        <Grid item container justify='center' spacing={2}>
          <Grid item>
            <Typography variant='body1'>{data.user.posts.length} Posts</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>{data.user.fans.length} Fans</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>{data.user.fanOf.length} Following</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='h4'>{data.user.displayName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6'>{data.user.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant ='h6' color='textSecondary'>@maxcami</Typography>
        </Grid>
        <Grid item container justify='center' className={classes.labels} spacing={2}>
          {data.user.labels.map(genre => (
            <Grid item>
              <Chip label={genre} className={classes.chips} />
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Typography variant='body2'>{data.user.bio}</Typography>
        </Grid>
        <Grid item container justify='space-evenly' className={classes.postsSection}>
          <Grid item>
            <Button size='small' disableRipple={true} className={classes.postCategories}>Posts</Button>
          </Grid>
          <Grid item>
            <Button size='small' disableRipple={true} className={classes.postCategories}>Promos</Button>
          </Grid>
          <Grid item>
            <Button size='small' disableRipple={true} className={classes.postCategories}>Private</Button>
          </Grid>
        </Grid>
      </Grid>
    )
}

export default Profile;