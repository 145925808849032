import React from 'react';
import Grid from '@material-ui/core/Grid';
import {useForm} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link, useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(23),
        heigth: theme.spacing(23)
    },
    formInput: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    formInputBG: {
        backgroundColor: 'rgb(245, 245, 245)',
    },
    formSubmit: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
        boxShadow: 'none',
        backgroundColor: 'rgb(62, 149, 239)',
    },
    ResetPW: {
        fontWeight: 'bold'
    },
    ResetPWItem: {
        marginBottom: theme.spacing(1),
    },
    Divider: {
        background: 'rgb(210, 210, 210)',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    DividerItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

const ForgotPW = () => {
    const classes = useStyles();
    const history = useHistory();
    const { register, setError, errors, clearErrors, handleSubmit } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        history.push('/newpassword');
    }

    return (
        <Grid container direction='column' alignItems='center' spacing={1}>
            <form noValidate onSubmit={handleSubmit(onSubmit)} id='form' />
            <Grid item>
                <Typography variant='h4' className={classes.ResetPW}>
                    Reset Password
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body1' color='textSecondary'>
                    Please provide your username to continue.
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    className={classes.formInput}
                    size='small'
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    label='Username'
                    variant="outlined"
                    id='username'
                    name='username'
                    autoComplete='username'
                    inputRef={register(
                        {
                            required: 'This field is required', 
                            minLength: {
                                value: 3,
                                message: 'Username must be a minimum of 3 characters'
                            }
                        }
                    )}
                    error={errors.username}
                    helperText={errors.username?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <Button
                    className={classes.formSubmit}
                    variant='contained'
                    type='submit'
                    color='primary'
                    form='form'
                    disableElevation
                >
                    Next
                </Button>
            </Grid>
            <Grid item className={classes.DividerItem}>
                <Divider variant='middle' className={classes.Divider} />
            </Grid>
            <Grid item>
                <Typography variant='body2' color='textSecondary'>
                    Remember your password?&nbsp;
                    <Link to='/' style={{textDecoration: 'none'}}>
                        Log In
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ForgotPW;