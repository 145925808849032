import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import {useForm} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChosenGenres from './ChooseGenres';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(20),
        heigth: theme.spacing(20)
    },
    formInput: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    formInputBG: {
        backgroundColor: 'rgb(245, 245, 245)'
    }, 
    formSubmit: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
        boxShadow: 'none',
        backgroundColor: 'rgb(62, 149, 239)'
    },
    profileButtons: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(30),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(15),
        },
    },
    ProfileInfo: {
        fontWeight: 'bold'
    },
    ProfileCaption: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    itemContainer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    avi: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    aviBadge: {
        backgroundColor: 'rgb(226, 187, 70)',
        color: 'black',
        border: '1.5px solid black',
        borderRadius: '50%',
        padding: theme.spacing(0.2),
    }
}));

const SignUpInfo2 = () => {
    const classes = useStyles();
    const { register, setError, errors, clearErrors, handleSubmit } = useForm();
    const [location, setLocation] = useState(null);
    const [loadLocation, setLoadLocation] = useState(false);
    const [mediasList, setMediasList] = useState([]);
    const [chooseMedias, setChooseMedias] = useState(false);
    const [avi, setAvi] = useState(null);

    const onSubmit = (data) => {
        console.log(data);
    }

    const getLocation = () => {
        setLoadLocation(true);
        // Check if location permission has been given
        navigator.geolocation.getCurrentPosition(
            async (pos) => {
                if (!('fetch' in window)) {
                    console.log('Fetch API not found, try including the polyfill');
                    return;
                }
                // &result_type=country|administrative_area_level_1|administrative_area_level_2|postal_code
                await fetch(`
                    https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&result_type=administrative_area_level_2&key=AIzaSyCkIxU8npyoLIE-msAw6sEN8eQdAT5jXJM
                `)
                .then((res) => {
                    if (!res.ok) {
                        throw Error(res.statusText);
                    }
                    return res.json();
                })
                .then((response) => {
                    console.log("Reverse Geocode: ", response);
                    console.log("Formatted Response: ", response.results[0].formatted_address)
                    setLocation(response.results[0].formatted_address);
                })
                .catch((e) => {
                    console.log('Looks like there was a problem: \n', e);
                });
            },
            (error) => {
                console.log("Geolocation API ERROR: ", error);
            },
            // {enableHighAccuracy: true}
        );
        setLoadLocation(false);
    }

    return (
        <>
            {
                chooseMedias ? 
                <ChosenGenres 
                    setMediasList={setMediasList}
                    mediasList={mediasList}
                    setChooseMedias={setChooseMedias}
                />
                : 
                <Grid container direction='column' alignItems='center' spacing={1}>
                    <form noValidate onSubmit={handleSubmit(onSubmit)} id='form' />
                    <Grid item>
                        <Typography variant='h4' className={classes.ProfileInfo}>
                            Profile Information
                        </Typography>
                    </Grid>
                    <Grid item className={classes.ProfileCaption}>
                        <Typography variant='body1' color='textSecondary'>
                            Choose a avatar, display name, art medias, and more.
                        </Typography>
                    </Grid>
                    {/* Inner Container for Avatar selection, Display Name, Location and Genre */}
                    <Grid item container justify='center' alignItems='center' className={classes.itemContainer}>
                        <Grid item >
                            <IconButton
                                disableElevation
                                disableRipple
                                disableFocusRipple
                            >
                                <Avatar className={classes.avi}>
                                    <PersonIcon fontSize='large' />
                                </Avatar>
                            </IconButton>
                        </Grid>
                        <Grid item container direction='column' alignItems='center' spacing={1} xs={8}>
                            <Grid item>
                                <TextField
                                    className={classes.profileButtons}
                                    size='small'
                                    InputProps={{
                                        className: classes.formInputBG
                                    }}
                                    label='Display Name'
                                    variant="outlined"
                                    id='displayname'
                                    name='displayname'
                                    autoComplete='nickname'
                                    inputRef={register(
                                        {
                                            required: 'This field is required', 
                                            minLength: {
                                                value: 3,
                                                message: 'Display name must be a minimum of 3 characters'
                                            }
                                        }
                                    )}
                                    error={errors.displayname}
                                    helperText={errors.displayname?.message}
                                    form='form'
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.profileButtons}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={location ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    onClick={() => getLocation()}
                                >
                                    {location ? "Location Included" : "Allow Location"}
                                </Button>
                                {loadLocation && <CircularProgress size={24} />}
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.profileButtons}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={
                                        mediasList.length > 0 ? 
                                            <CheckBoxIcon /> 
                                        : 
                                            <CheckBoxOutlineBlankIcon />
                                    }
                                    onClick={() => setChooseMedias(true)}
                                >
                                    Add art media(s)
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.formInput}
                            size='small'
                            InputProps={{
                                className: classes.formInputBG
                            }}
                            label='Website'
                            variant="outlined"
                            multiline
                            id='website'
                            name='website'
                            autoComplete='url'
                            form='form'
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.formInput}
                            size='small'
                            InputProps={{
                                className: classes.formInputBG
                            }}
                            label='Bio'
                            variant="outlined"
                            multiline
                            id='bio'
                            name='bio'
                            autoComplete='off'
                            form='form'
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.formSubmit}
                            variant='contained'
                            type='submit'
                            color='primary'
                            form='form'
                            disableElevation
                        >
                            Sign Up
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' color='textSecondary'>
                            Already have an account?&nbsp;
                            <Link to='/' style={{textDecoration: 'none'}}>
                                Log In
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default SignUpInfo2;