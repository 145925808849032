import React, { useState }  from 'react';
import {Auth} from 'aws-amplify';
import styled from 'styled-components/macro';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import logo from '../assets/NEW MAXIME LOGO-01.svg';
import GoldClouds from '../assets/GoldClouds.jpg';
import {Route, Switch, useRouteMatch, useHistory} from 'react-router-dom';
import SignIn from './Auth Screens/SignIn';
import ForgotPW from './Auth Screens/ForgotPW';
import ConfirmNewPW from './Auth Screens/ConfirmNewPW';
import SignUpInfo1 from './Auth Screens/SignUpScreens/SignUpInfo1';
import SignUpInfo2 from './Auth Screens/SignUpScreens/SignUpInfo2';
import SignUpGenres from './Auth Screens/SignUpScreens/SignUpGenres';
import ConfirmSignUp from './Auth Screens/SignUpScreens/ConfirmSignUp';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Left = styled.div`
    height: 100%;
    width: 100%;
    background: rgb(229, 187, 70);
`;

const Right = styled.div`
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
`;

const StyledTypography = styled(Typography)`
    && {
        font-size: 8.25rem;
        font-weight: bold;
    }
`;

const Img = styled.img`
  width: 20%;
  height: 20%;
`;

const Brand = styled(Typography)`
    && {
        font-family: "Varela Round";
        font-size: 9rem;
        font-weight: 1000;
        background: url(${GoldClouds});
        background-size: cover;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }
`;

const AuthScreen = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {path, url} = useRouteMatch();
    const history = useHistory();

    return (
        <Container>
            <Hidden smDown>
                <Left>
                    <Brand variant='h1' component='h1'>
                        CRE8US:
                    </Brand>
                    <StyledTypography variant='h1' component='h1'>
                        The Social Network for All Creatives
                    </StyledTypography>
                </Left>
            </Hidden>
            <Right>
                <Img src={logo} alt="Logo" />
                
                <Switch>
                    {/* Add 'exact' */}
                    <Route exact path={`${path}`}> 
                        <SignIn />
                    </Route>
                    <Route path='/signupinfo1'>
                        <SignUpInfo1 /> 
                    </Route>
                    <Route path='/signupinfo2'>
                        <SignUpInfo2 /> 
                    </Route>
                    <Route path='/signupgenres'>
                        <SignUpGenres /> 
                    </Route>
                    <Route path='confirmsignup'>
                        <ConfirmSignUp /> 
                    </Route>
                    <Route path='/forgotpassword'>
                        <ForgotPW />
                    </Route>
                    <Route path='/newpassword'>
                        <ConfirmNewPW /> 
                    </Route>
                </Switch>

            </Right>
        </Container>
    )
}

export default AuthScreen;
