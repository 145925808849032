import React from 'react';

const SignUpGenres = () => {
    return (
        <div>
            Sign Up Genres
        </div>
    )
}

export default SignUpGenres;