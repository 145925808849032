import {InMemoryCache, makeVar} from '@apollo/client';

// Cache
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loggedIn: {
          read() {
            return loggedInVar(); // --> true | false
          },
        },
        userSub: {
          read() {
            return userSubVar(); // --> 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx' | 'none'
          },
        },
      },
    },
  },
});

// Reactive Variables
export const loggedInVar = makeVar(false);

export const userSubVar = makeVar('none');
