import React from 'react';
import Grid from '@material-ui/core/Grid';
import {useForm} from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link, useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    NewPWText: {
        fontWeight: 'bold'
    },
    input: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    inputBG: {
        backgroundColor: 'rgb(245, 245, 245)'
    },
    submit: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
        boxShadow: 'none',
        backgroundColor: 'rgb(62, 149, 239)'
    },
    divider: {
        background: 'rgb(210, 210, 210)',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    dividerItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    caption: {
        marginBottom: theme.spacing(1)
    }
}));

const ConfirmNewPW = () => {
    const classes = useStyles();
    const history = useHistory();
    const { register, setError, errors, clearErrors, handleSubmit } = useForm();

    const onSubmit = (data) => {
        if (data.password !== data.confirmpassword) {
            setError('confirmpassword', {
                type: 'manual',
                message: 'Passwords do not match'
            });
        } else {
            // clearErrors('confirmpassword');
            console.log(data);
            // history.push('signupinfo2', );
        }
    }

    return (
        <Grid container direction='column' alignItems='center' spacing={1}>
            <form noValidate onSubmit={handleSubmit(onSubmit)} id='form' />
            <Grid item>
                <Typography variant='h4' className={classes.NewPWText}>
                    New Password
                </Typography>
            </Grid>
            <Grid item className={classes.caption}>
                <Typography variant='body1' color='textSecondary'>
                    Please provide the code sent to your email and your new password.
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    className={classes.input}
                    InputProps={{
                        className: classes.inputBG
                    }}
                    size='small'
                    variant='outlined'
                    label='Code'
                    id='code'
                    name='code'
                    autoComplete='one-time-code'
                    inputRef={register(
                        {
                            required: "This field is required.",
                            minLength: {
                                value: 6,
                                message: "This field needs to be 6 digits."
                            },
                            maxLength: {
                                value: 6,
                                message: "This field can only be 6 digits."
                            }
                        }
                    )}
                    error={errors.code}
                    helperText={errors.code?.message}
                    form='form'
                />
            </Grid>
            <Grid item className={classes.dividerItem}>
                <Divider variant='middle' className={classes.divider} />
            </Grid>
            <Grid item>
                <TextField
                    className={classes.input}
                    InputProps={{
                        className: classes.inputBG
                    }}
                    size='small'
                    variant='outlined'
                    label='New Password'
                    id='newpassword'
                    name='newpassword'
                    autoComplete='new-password'
                    inputRef={register(
                        {
                            required: 'This field is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be a minimum of 8 characters'
                            }, 
                            pattern: {
                                value: /^[A-Za-z0-9_#&+-]+$/,
                                message: 'Invalid characters used'
                            }
                        }
                    )}
                    error={errors.code}
                    helperText={errors.newpassword?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <TextField
                    className={classes.input}
                    InputProps={{
                        className: classes.inputBG
                    }}
                    size='small'
                    variant='outlined'
                    label='Confirm New Password'
                    id='confirmNewPW'
                    name='confirmNewPW'
                    autoComplete='new-password'
                    inputRef={register(
                        {
                            required: 'This field is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be a minimum of 8 characters'
                            }, 
                            pattern: {
                                value: /^[A-Za-z0-9_#&+-]+$/,
                                message: 'Invalid characters used'
                            }
                        }
                    )}
                    error={errors.code}
                    helperText={errors.confirmNewPW?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <Button
                    className={classes.submit}
                    variant='contained'
                    type='submit'
                    color='primary'
                    form='form'
                    disableElevation
                >
                    Submit
                </Button>
            </Grid>
            <Grid item>
                <Typography variant='body2' color='textSecondary'>
                    Having trouble?&nbsp;
                    <Link style={{textDecoration: 'none'}}>
                        Resend Code
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ConfirmNewPW;