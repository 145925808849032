import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Auth} from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link, useHistory} from 'react-router-dom';
import {userSubVar, loggedInVar} from '../../local state/cache';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    formInput: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    formInputBG: {
        backgroundColor: 'rgb(245, 245, 245)',
    },
    formSubmit: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
        boxShadow: 'none',
    },
    signup: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    LogIn: {
        fontWeight: 'bold'
    },
    LogInItem: {
        marginBottom: theme.spacing(3),
    }
}));

const SignIn = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { register, errors, handleSubmit } = useForm();
    const history = useHistory();

    const onSubmit = async data => {
        // try {
        //     setLoading(true);
        //     var sub = '';
        //     await Auth.signIn(data.username, data.password).then(user => {
        //       console.log(user);
        //       sub = user.attributes.sub;
        //       localStorage.setItem('username', data.username);
        //       localStorage.setItem('password', data.password);
        //     });
        //     // TO-DO: Securely save username and password in local state
        //     userSubVar(sub);
        //     setLoading(false);
        //     loggedInVar(true);
        //     history.replace('/main/home');
        // } catch (error) {
        //     if (error.code === 'UserNotConfirmedException') {
        //         // TO-DO: React-Router navigate to 'Confirm Sign-Up'
        //     }
        //     console.log(error);
        // }
    };

    return (
        <Grid container direction='column' alignItems='center' spacing={1}>
            <form noValidate onSubmit={handleSubmit(onSubmit)} id='form' />
            <Grid item className={classes.LogInItem}>
                <Typography variant='h4' className={classes.LogIn}>
                    Log in to Cre8us
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    label='Username or Email'
                    size='small'
                    variant="outlined"
                    className={classes.formInput}
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    id='username'
                    name='username'
                    autoComplete='username'
                    inputRef={register(
                        {
                            required: 'This field is required', 
                            minLength: {
                                value: 3,
                                message: 'Username must be a minimum of 3 characters'
                            }
                        }
                    )}
                    error={errors.username}
                    helperText={errors.username?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <TextField
                    label='Password'
                    size='small'
                    variant="outlined"
                    className={classes.formInput}
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    id='password'
                    name='password'
                    autoComplete='current-password'
                    type='password'
                    inputRef={register(
                        {
                            required: 'This field is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be a minimum of 8 characters'
                            }, 
                            pattern: {
                                value: /^[A-Za-z0-9_#&+-]+$/,
                                message: 'Invalid characters used'
                            }
                        }
                    )}
                    error={errors.password}
                    helperText={errors.password?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <Button
                    className={classes.formSubmit}
                    type='submit'
                    variant='outlined'
                    color='primary'
                    size='large'
                    form='form'
                >
                    {!loading ? 'Log In' : 'Loading...'}
                </Button>
            </Grid>
            <Grid item container justify='space-between' className={classes.signup}>
                <Grid item>
                    <Typography align='left' variant='body1'>
                        <Link to='/forgotpassword' style={{textDecoration: 'none'}}>
                            Forgot Password
                        </Link>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography align='right' variant='body1'>
                        Don't have an account?&nbsp;
                        <Link to='/signupinfo1' style={{textDecoration: 'none'}}>
                            Sign up
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SignIn;