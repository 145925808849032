import React, { useState } from 'react';
import logo from '../assets/NEW MAXIME LOGO-04.svg';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components/macro';
import {Auth} from 'aws-amplify';
import {loggedInVar} from '../local state/cache';
import MenuItem from '@material-ui/core/MenuItem';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import {Switch, useRouteMatch, useHistory} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Profile from './App Screens/Profile';
import Home from './App Screens/Home';
import Grid from '@material-ui/core/Grid';

const Container = styled.div`
    flex-grow: 1;
`;

const Img = styled.img`
    width: 4%;
    height: 4%;
`;

const StyledAvatar = styled(Avatar)`
    width: 1%;
    height: 1%;
`;

const SubAppBar = styled.div`
    margin-left: auto;
`;

const Main = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {path, url} = useRouteMatch();
    const history = useHistory();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container>
            <AppBar position="static" color="inherit">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="default"
                        aria-label="menu"
                        disableRipple={true}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Img src={logo} alt="Logo" />
                    <SubAppBar>
                        <IconButton
                            color='default'
                            disableRipple={true}
                            onClick={() => history.push(`${url}/home`)}
                        >
                            <HomeRoundedIcon />
                        </IconButton>
                        <IconButton></IconButton>
                        <IconButton
                            color='default'
                            disableRipple={true}
                            onClick={() => history.push(`${url}/profile`)}
                        >
                            <PersonSharpIcon />
                        </IconButton>
                        <IconButton
                            color='default'
                            disableRipple={true}
                            onClick={handleClick}
                        >
                            <StyledAvatar>M</StyledAvatar>
                        </IconButton>
                        <Menu
                            id='avatar-menu'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem 
                                onClick={
                                    async () => {
                                        try {
                                            await Auth.signOut();
                                            // TO-DO: Securely remove user data from offline DB
                                            localStorage.clear();
                                            console.log('User signed out...');
                                            loggedInVar(false);
                                        } catch (err) {
                                            console.log('Could not sign out: ' + err.message);
                                        }
                                    }
                                }
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </SubAppBar>
                </Toolbar>
            </AppBar>

            <Switch>
                <PrivateRoute path={`${path}/profile`}>
                    <Profile />
                </PrivateRoute>
                <PrivateRoute path={`${path}/home`}>
                    <Home />
                </PrivateRoute>
            </Switch>
        </Container>
    )
}

export default Main;