import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
// import {
//   ApolloProvider,
//   ApolloClient,
//   HttpLink,
// } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import {cache} from './local state/cache';
// import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
// import APIconfig from './endpoints';
// import {Auth} from 'aws-amplify';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';

/**--------------------------------------AMPLIFY---------------------------------------------- */
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    userPoolId: 'us-east-1_hpdH4AS9P',
    identityPoolId: 'us-east-1:73d3a020-3b96-4e90-80ef-51257e150e62',
    region: 'us-east-1',
    userPoolWebClientId: '6c1ahmns5ejc495ngtlletf3p2',
    // storage: MyStorage,
  },
  Storage: {
    AWSS3: {
      bucket: 'creatistcontent',
      region: 'us-east-1',
    },
  },
});

// const persist = async () => {
//   await persistCache({
//     cache,
//     storage: new LocalStorageWrapper(window.localStorage),
//   });
// }

// persist();

// // Link (w/ Authorization Header)
// const httpLink = new HttpLink({uri: APIconfig.APIGatewayEndpoint + '/graphql'});

// const authLink = setContext(async (_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = (await Auth.currentSession()).getIdToken().getJwtToken();
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });

// // Apollo Cient
// const client = new ApolloClient({
//   cache: cache,
//   link: authLink.concat(httpLink),
//   credentials: 'include',
// });

  // link: concat(authMiddleware, httpLink),

ReactDOM.render(
  <React.StrictMode>
    {/* <ApolloProvider client={client}> */}
      <Router>
          <App />
      </Router>
    {/* </ApolloProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
