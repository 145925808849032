import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {Route, Switch, useHistory} from 'react-router-dom';
import Main from './Screens/Main';
import AuthScreen from './Screens/Auth';
import Legal from './Screens/Legal';
import {userSubVar, loggedInVar, cache} from './local state/cache';
import PrivateRoute from './Screens/PrivateRoute';
import {ApolloClient, ApolloProvider, HttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import APIconfig from './endpoints';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import logo from './assets/NEW MAXIME LOGO-01.svg';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '35%',
    height: '35%',
  }
}));

const App = () => {
  const classes = useStyles();
  const history = useHistory();
  const [client, setClient] = useState();
  const [persistor, setPersistor] = useState();
  
  useEffect(() => {
    const init = async () => {
      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write'
      });
      await newPersistor.restore();
      setPersistor(newPersistor);

      const httpLink = new HttpLink({uri: APIconfig.APIGatewayEndpoint + '/graphql'});

      const authLink = setContext(async (_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          }
        }
      });

      setClient(
        new ApolloClient({
          cache: cache,
          link: authLink.concat(httpLink),
          credentials: 'include',
        }),
      );
    }

    const currentSession = async () => {
      try {
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        if (username != null && password != null) {
          await Auth.currentAuthenticatedUser().then(
            (user) => {
              console.log('App User sign in: ', user);
              loggedInVar(true);
              userSubVar(user.attributes.sub);
              history.replace('main/profile');
            },
          );
        }
      } catch (error) {
        console.log('currentSession: ', error); // --> not authenticated
      }
    };

    init().catch(console.error);
    currentSession();
  }, []);

  if (!client) {
    return (
      <Grid container justify='center' alignItems='center'>
        <Grid item>
          <img src={logo} alt="Logo" className={classes.logo} />
        </Grid>
      </Grid>
    );
  }

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path='/'>
          <AuthScreen />
        </Route>
        <Route path='/legal'>
          <Legal />
        </Route>
        <PrivateRoute path='/main'>
          <Main />
        </PrivateRoute>
      </Switch>
    </ApolloProvider>
  );
}

export default App;
