import React from 'react';

const ConfirmSignUp = () => {
    return (
        <div>
            Confirm Sign Up
        </div>
    )
}

export default ConfirmSignUp;