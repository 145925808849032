import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import {Route, Switch, useRouteMatch, Link} from 'react-router-dom';
import Terms from './Legal Screens/Terms';
import Privacy from './Legal Screens/Privacy';
import Cookies from './Legal Screens/Cookies';
import IconButton from '@material-ui/core/IconButton';
import logo from '../assets/NEW MAXIME LOGO-04.svg';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(1),
        height: theme.spacing(1)
    }
}));

const Legal = () => {
    const {path, url} = useRouteMatch();
    const classes = useStyles();

    return (
        <Grid container direction='column' alignItems='center' spacing={1}>
            <Grid item>
                <AppBar>
                    <ToolBar>
                        <IconButton
                            edge="start"
                            color="default"
                            disableRipple={true}
                        >
                            <img src={logo} alt="Logo"/>
                        </IconButton>
                    </ToolBar>
                </AppBar>
            </Grid>

            <Switch>
                <Route path={`${path}/terms`}>
                    <Terms /> 
                </Route>
                <Route path={`${path}/privacy`}>
                    <Privacy /> 
                </Route>
                <Route path={`${path}/cookies`}>
                    <Cookies /> 
                </Route>
            </Switch>

        </Grid>
    );
}

export default Legal;