import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logo from '../../../assets/NEW MAXIME LOGO-01.svg';
import {useForm} from 'react-hook-form';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(23),
        heigth: theme.spacing(23)
    },
    formInput: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    formInputBG: {
        backgroundColor: 'rgb(245, 245, 245)',
    }, 
    formSubmit: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
        boxShadow: 'none',
        backgroundColor: 'rgb(62, 149, 239)',
    },
    SignUp: {
        fontWeight: 'bold'
    },
    TermsItem: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    },
    CaptionItem: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(50),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(35),
        },
    }
}))

const SignUpInfo1 = () => {
    const {url} = useRouteMatch();
    const classes = useStyles();
    const history = useHistory();
    const { register, setError, errors, clearErrors, handleSubmit } = useForm();

    const onSubmit = (data) => {
        if (data.password !== data.confirmpassword) {
            setError('confirmpassword', {
                type: 'manual',
                message: 'Passwords do not match'
            });
        } else {
            clearErrors('confirmpassword');
            console.log(data);
            history.push('signupinfo2');
        }
    }

    return (
        <Grid item container direction='column' alignItems='center' spacing={1}>
            <form noValidate onSubmit={handleSubmit(onSubmit)} id='form' />
            <Grid item>
                <Typography variant='h4' className={classes.SignUp}>
                    Sign up for Cre8us
                </Typography>
            </Grid>
            <Grid item className={classes.CaptionItem}>
                <Typography variant='body1' color='textSecondary'>
                    Connect, collab and create among other creatives.
                    Or just enjoy some great artwork.
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    className={classes.formInput}
                    size='small'
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    label='Username'
                    variant="outlined"
                    id='username'
                    name='username'
                    autoComplete='username'
                    inputRef={register(
                        {
                            required: 'This field is required', 
                            minLength: {
                                value: 3,
                                message: 'Username must be a minimum of 3 characters'
                            }
                        }
                    )}
                    error={errors.username}
                    helperText={errors.username?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <TextField
                    className={classes.formInput}
                    size='small'
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    label='Name'
                    variant="outlined"
                    id='name'
                    name='name'
                    autoComplete='name'
                    inputRef={register(
                        {
                            required: 'This field is required', 
                            minLength: {
                                value: 3,
                                message: 'Name must be a minimum of 3 characters'
                            }
                        }
                    )}
                    error={errors.name}
                    helperText={errors.name?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <TextField
                    className={classes.formInput}
                    size='small'
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    label='Email'
                    variant="outlined"
                    id='email'
                    name='email'
                    autoComplete='email'
                    inputRef={register(
                        {
                            required: 'This field is required',
                        }
                    )}
                    error={errors.email}
                    helperText={errors.email?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <TextField
                    className={classes.formInput}
                    size='small'
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    label='Password'
                    variant="outlined"
                    id='password'
                    name='password'
                    autoComplete='new-password'
                    type='password'
                    inputRef={register(
                        {
                            required: 'This field is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be a minimum of 8 characters'
                            }, 
                            pattern: {
                                value: /^[A-Za-z0-9_#&+-]+$/,
                                message: 'Invalid characters used'
                            }
                        }
                    )}
                    error={errors.password}
                    helperText={errors.password?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <TextField
                    className={classes.formInput}
                    size='small'
                    InputProps={{
                        className: classes.formInputBG
                    }}
                    label='Confirm Password'
                    variant="outlined"
                    id='confirmpassword'
                    name='confirmpassword'
                    autoComplete='new-password'
                    type='password'
                    inputRef={register(
                        {
                            required: 'This field is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be a minimum of 8 characters'
                            }, 
                            pattern: {
                                value: /^[A-Za-z0-9_#&+-]+$/,
                                message: 'Invalid characters used'
                            }
                        }
                    )}
                    error={errors.confirmpassword}
                    helperText={errors.confirmpassword?.message}
                    form='form'
                />
            </Grid>
            <Grid item>
                <Button
                    className={classes.formSubmit}
                    variant='contained'
                    type='submit'
                    color='primary'
                    form='form'
                    disableElevation
                >
                    Next
                </Button>
            </Grid>
            <Grid item className={classes.TermsItem}>
                <Typography variant='caption' color='textSecondary'>
                    By continuing, you agree to the&nbsp;
                    <Link 
                        to='/legal/terms'
                        style={{
                            textDecoration: 'none',
                            color: 'black',
                            fontWeight: 'bold'
                        }}
                    >
                        Terms of Service
                    </Link>,&nbsp; 
                    <Link 
                        to='/legal/privacy'
                        style={{
                            textDecoration: 'none',
                            color: 'black',
                            fontWeight: 'bold'
                        }}
                    >
                        Privacy Policy
                    </Link>,&nbsp;
                    and&nbsp;
                    <Link
                        to='/legal/cookies'
                        style={{
                            textDecoration: 'none',
                            color: 'black',
                            fontWeight: 'bold'
                        }}
                    >
                        Cookies Policy.
                    </Link>
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant='body2' color='textSecondary'>
                    Already have an account?&nbsp;
                    <Link to='/' style={{textDecoration: 'none'}}>
                        Log In
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default SignUpInfo1;