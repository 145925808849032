import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link, useHistory} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const Terms = () => {
    return (
        <Grid item container direction='column' alignItems='flex-start' spacing={1}>
            <Grid item>
                <Typography variant='h1'>
                    Terms of Service
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Terms;