const medias = [
    {
        media: "Architecture",
        type: {
            Classic: "Classic",
            Romanesque: "Romanesque",
            Gothic: "Gothic",
            Baroque: "Baroque",
            Neoclassical: "Neoclassical",
            Beaux_Arts: "Beaux-Arts",
            Art_Nouveau: "Art Nouveau",
            Art_Deco: "Art Deco",
            Bauhaus: "Bauhaus",
            Modern: "Modern",
            Postmodern: "Postmodern",
            Deconstructivism: "Deconstructivism"
        }
    },
    {media: "Carpentry"},
    {
        media: "Ceramics",
        type: {
            Earthenware: "Earthenware",
            Stoneware: "Stoneware",
            Porcelain: "Porcelain"
        }
    },
    {media: "Culinary"},
    {
        media: "Drawing",
        type: {
            Academy: "Academy",
            Anime: "Anime",
            Cartoon: "Cartoon",
            Comic: "Comic",
            Manga: "Manga",
            Fashion: "Fashion",
            Figure: "Figure",
            Gestural: "Gestural",
            Line_Art: "Line Art",
            Portrait: "Portrait",
            Sketch: "Sketch",
            Stick_Figure: "Stick Figure",
            Technical: "Technical",
            Realism: "Realism",
            Pointtilism: "Pointtilism"
        }
    },
    {media: "Dance"},
    {
        media: "Digital Art",
        type: {
            Photoshop: "Photoshop",
            Collage: "Collage",
            Gaming_Art: "Gaming Art",
            Fractal: "Fractal",
            Dynamic_Painting: "Dynamic Painting",
            Data_Moshing: "Data Moshing",
            TwoD: "2D",
            ThreeD: "3D",
            Pixel: "Pixel",
            Album_Cover: "Album Cover"
        }
    },
    {media: "Videography"},
    {media: "Photography"},
    {
        media: "Film",
        type: {
            Action: "Action",
            Adventure: "Adventure",
            Animated: "Animated",
            Biography: "Biography",
            Cinematic: "Cinematic",
            Comedy: "Comedy",
            Crime: "Crime",
            Drama: "Drama",
            Documentry: "Documentary",
            Fantasy: "Fantasy",
            Noir: "Noir",
            Historical: "Historical",
            Horror: "Horror",
            Mystery: "Mystery",
            Sci_Fi: "Sci-Fi",
            Sport: "Sport",
            Thriller: "Thriller",
            War: "War",
            Western: "Western"
        }
    },
    {
        media: "Glass",
        type: {
            Hot_Glass: "Hot Glass",
            Warm_Glass: "Warm Glass",
            Cold_Glass: "Cold Glass"
        }
    },
    {
        media: "Sculpting",
        type: {
            Carving: "Carving",
            Assembling: "Assembling",
            Modeling: "Modeling",
            Casting: "Casting",
            Installation: "Installation"
        }
    },
    {
        media: "Singing",
        type: {
            Soprano: "Soprano",
            Mezzo_soprano: "Mezzo-Soprano",
            Contralto: "Contralto",
            Countertenor: "Countertenor",
            Tenor: "Tenor",
            Baritone: "Baritone",
            Bass: "Bass"
        }
    },
    {
        media: "Theatre",
        type: {
            Domestic: "Domestic",
            Comedy: "Comedy",
            Documentary: "Documentary",
            Drama: "Drama",
            Epic: "Epic",
            Fantasy: "Fantasy",
            Historical: "Historical",
            Musical: "Musical",
            Puppetry: "Puppetry",
            Tragedy: "Tragedy",
            Absurdism: "Absurdism",
            Expressionism: "Expressionism",
            Melodrama: "Melodrama",
            Modernism: "Modernism",
            Naturalism: "Naturalism",
            Postmodern: "Postmodern",
            Realism: "Realism"
        }
    },
    {
        media: "Music",
        type: {
            Hip_Hop: "Hip-Hop",
            Rap: "Rap",
            Country: "Country",
            Pop: "Pop",
            RB: "R&B",
            Chill: "Chill",
            Dance: "Dance",
            Latin: "Latin",
            Electronic: "Electronic",
            Christian: "Christian",
            Rock: "Rock",
            Metal: "Metal",
            Acoustic: "Acoustic",
            Indie: "Indie",
            Classical: "Classical",
            Jazz: "Jazz",
            Reggae: "Reggae",
            Soul_Funk: "Soul/Funk",
            Gospel: "Gospel",
            Blues: "Blues",
            Afro: "Afro",
            KPop: "K-Pop"
        }
    },
    {
        media: "Print Making",
        type: {
            Woodcut_Linocut: "Woodcut/Linocut",
            Engraving: "Engraving",
            Etching: "Etching",
            Collagraph: "Collagraph",
            Aquatint: "Aquatint",
            Monotype_Monoprint: "Monotype/Monoprint",
            Lithography: "Lithography",
            ScreenPrinting: "Screen Printing",
            Digital_Print: "Digital Print",
            Mezzotint: "Mezzotint",
            Drypoint: "Drypoint",
            Foil_Imaging: "Foil Imaging"
        }
    },
    {
        media: "Textile",
        type: {
            Wool: "Wool",
            Silk: "Silk",
            Cotton: "Cotton",
            Flax: "Flax",
            Jute: "Jute",
            Bamboo: "Bamboo",
            Asbestos: "Asbestos",
            Glass_Fibre: "Glass Fibre",
            Nylon: "Nylon",
            Polyester: "Polyester",
            Acrylic: "Acrylic",
            Rayon: "Rayon"
        }
    },
    {media: "Graffiti"},
    {
        media: "Fashion",
        type: {
            Sporty: "Sporty",
            Bohemian: "Bohemian",
            Grunge: "Grunge",
            Preppy: "Preppy",
            Punk: "Punk",
            Streetwear: "Streetwear",
            Classic: "Classic",
            Casual: "Casual"
        }
    },
    {media: "Model"},
    {media: "Cosplay"},
]

export default medias;